



















import Vue from 'vue'
import {Project} from "@/types/project";

export default Vue.extend({
  name: 'PortfolioItem',
  props: {
    projectObject: {
      type: Object as () => Project,
      required: true
    }
  }
})
