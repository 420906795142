








import Vue from 'vue';
import PortfolioItem from "@/components/PortfolioItem.vue";
import {loadAllProjects} from "@/model/portfolioModel";
import {Project} from "@/types/project";

export default Vue.extend({
  components: {PortfolioItem},
  computed: {
    projects(): Project[] {
      return loadAllProjects();
    }
  }
})
