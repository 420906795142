























import Vue from 'vue'
import {Project} from "@/types/project";
import {loadProject} from "@/model/portfolioModel";
import md from "@/model/markdownRenderer";

export default Vue.extend({
  name: 'ProjectView',
  props: {
    projectSlug: {
      type: String,
      required: true
    }
  },
  computed: {
    renderedContent(): string {
      return md.render(this.project.content);
    },
    project(): Project {
      return loadProject(this.projectSlug);
    }
  }
})
